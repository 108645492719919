import React, { Fragment, useEffect, useState } from "react";
import ActionBar from "./ActionBar";
import _ from "lodash";
import { navigate, useLocation } from "@reach/router";
import styled from "styled-components";
import themes from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import TranscriberService from "../../../services/TranscriberService";
import { getErrorMessage } from "../../../helpers/functions";
import useLoader from "../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import TranscriptEditor from "../../transcripts/TranscriptEditor/index";
import useUser from "../../../hooks/useUser";

const TranscriptEdit = (id) => {
  const history = useLocation();
  const resolutionKind = history.search.replace("?caseType=", "");
  const breadcrumbs = ["Cases", _.startCase(resolutionKind), "Transcript"];
  const location = useLocation();
  const [data, setData] = useState([
    {
      id: "",
      start: "",
      end: "",
      text: "",
      speaker: "",
      confidence: "",
      count: 0,
      mediaId: "",
      transcriptionFlag: "",
      speechFinal: "",
      utteranceEnd: "",
      lastTranscriptEndTime: "",
    },
  ]);
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();
  const transcriptId = location?.state?.transcriptId;
  const [mediaUrl, setMediaUrl] = useState();
  const [discard, setDiscard] = useState([
    {
      id: "",
      start: "",
      end: "",
      text: "",
      speaker: "",
      confidence: "",
      mediaId: "",
      transcriptionFlag: "",
      speechFinal: "",
      utteranceEnd: "",
      lastTranscriptEndTime: "",
    },
  ]);
  const [refreshDetails, setRefreshDetails] = useState(true);
  const [render, setRender] = useState(false);
  const caseId = history.state.caseId;
  const captureText = true;
  const { userObject } = useUser();
  const [state, setState] = useState({ state: false });

  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case resolutionKind:
        navigate(`/transcriber/cases?caseType=${resolutionKind}`);
        break;
      case "cases":
        navigate(`/transcriber/cases?caseType=${resolutionKind}`);
        break;
      default:
        break;
    }
  };

  const updateTranscriptionData = async (data) => {
    try {
      setLoader({ state: true, message: "Adding Transcript..." });
      const payload = {
        editedTranscriptionData: data,
      };
      const res = await TranscriberService.updateTranscriptionData(
        payload,
        transcriptId,
      );
      if (res?.updated) {
        setRefreshDetails(true);
      }
      if (res?.message) {
        enqueueSnackbar(res?.message, {
          variant: "success",
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setState({ state: false });
    }
  };

  useEffect(() => {
    async function getData(transcriptId) {
      try {
        setLoader({ state: true, message: "Fetching Transcription..." });
        const res = await TranscriberService.getLiveTranscription(transcriptId);
        if (res?.crossExamination) {
          navigate(
            `/transcriber/cases/${caseId}/cross-examination-transcript?caseType=${resolutionKind}`,
            {
              state: {
                value: history.state,
              },
            },
          );
        } else {
          setData(
            res?.transcriptData?.words?.map((el) => {
              return {
                id: el?.id,
                end: el?.end,
                confidence: el?.confidence,
                text: el?.text ? el?.text : "",
                start: el?.start,
                speaker: el?.speaker,
                mediaId: el?.mediaId,
                transcriptionFlag: el?.transcriptionFlag,
                speechFinal: el?.speechFinal,
                utteranceEnd: el?.utteranceEnd,
                lastTranscriptEndTime: el?.lastTranscriptEndTime,
              };
            }),
          );
          setDiscard(
            res?.transcriptData?.words?.map((el) => {
              return {
                id: el?.id,
                end: el?.end,
                confidence: el?.confidence,
                text: el?.text ? el?.text : "",
                start: el?.start,
                speaker: el?.speaker,
                mediaId: el?.mediaId,
                transcriptionFlag: el?.transcriptionFlag,
                speechFinal: el?.speechFinal,
                utteranceEnd: el?.utteranceEnd,
                lastTranscriptEndTime: el?.lastTranscriptEndTime,
              };
            }),
          );
          setMediaUrl(res);
          setRender(true);
        }
      } catch (error) {
        console.log("failed");
      } finally {
        setLoader({ state: false });
      }
    }
    if (refreshDetails) {
      getData(transcriptId);
      setRefreshDetails(false);
    }
  }, [refreshDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  const discardChanges = () => {
    setData(
      discard.map((el) => {
        return {
          id: el?.id,
          end: el?.end,
          confidence: el?.confidence,
          text: el?.text,
          start: el?.start,
          speaker: el?.speaker,
          mediaId: el?.mediaId,
          transcriptionFlag: el?.transcriptionFlag,
          speechFinal: el?.speechFinal,
          utteranceEnd: el?.utteranceEnd,
          lastTranscriptEndTime: el?.lastTranscriptEndTime,
        };
      }),
    );
    setRender(true);
  };

  const actions = [
    {
      text: "Refresh",
      props: {
        onClick: () => setRefreshDetails(true),
      },
      // hidden: data ? true : false,
    },
    {
      text: "Discard",
      props: {
        onClick: () => discardChanges(),
      },
      hidden: data ? false : true,
    },
    {
      text: "Save (alt + s)",
      props: {
        onClick: () => updateTranscriptionData(data),
      },
      disabled: mediaUrl?.transcriberEditMode ? false : true,
      hidden: data ? false : true,
    },
    // {
    //   text: "Submit",
    //   props: {
    //     onClick: () => submitVideoRecTranscription(transcriptId),
    //   },
    //   hidden: mediaUrl?.transcriberEditMode ? false : true,
    // },
  ];

  const autoSaveupdateTranscriptionData = async (data) => {
    try {
      const payload = {
        editedTranscriptionData: data,
      };
      const res = await TranscriberService.updateTranscriptionData(
        payload,
        transcriptId,
      );
      if (res?.message) {
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setState({ state: false });
    }
  };

  return (
    <Fragment>
      <ActionBar {...{ breadcrumbs, onBreadcrumbClick, actions }} />
      <Container>
        {data && (
          <TranscriptEditor
            {...{
              transcriptId,
              data,
              render,
              setRender,
              captureText,
              state,
              setState,
            }}
            mediaUrl={mediaUrl?.audioFileUrl}
            title={mediaUrl?.title}
            name={userObject?.name}
            role={userObject?.role}
            setData={setData}
            updateState={updateTranscriptionData}
            autoSaveupdateTranscriptionData={autoSaveupdateTranscriptionData}
            transcriberAccess={mediaUrl?.transcriberEditMode}
          />
        )}
      </Container>
    </Fragment>
  );
};

export default TranscriptEdit;

export const Container = styled.div`
  padding: 4px 43px;
  display: flex;
  flex-direction: column;
`;

export const HeadingContainer = styled.span`
  margin-bottom: 31px;
  font-family: ${themes.fonts.primaryFontSemiBold};
  font-size: ${(props) => (props.size ? props.size : "13px")};
  color: ${COLORS.BTN_GREEN};
  // text-transform: uppercase;
  cursor: pointer;
  & .topSection {
    background: ${COLORS.BOX_DARKGREEN};
  }
`;
